/* Reset default margin and padding for all elements */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Body styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

#drop_down_list {
    font-size: 20px;
}

/* Container styles */
.admin-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Title styles */
.admin-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #c27800;
}

/* Section styles */
.admin-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}
h2 {
    font-size: 24px;
    padding-bottom: 10px;
    color: #47484d;

}


#create_close_btn {
  margin-left: 20px;
    height: 60px;
    width: 200px;
    font-size: 20px;
}
#create_close_div {
    margin-top: 10px;

}
#create_btn {

    font-size: 20px;
    height: 60px;
    width: 200px;
}
/* Input styles */
.input-group {
    display: flex;
    gap: 10px;
    align-items: center;
}

.input-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
    font-size: 22px;

}



.admin-button:hover {
    background-color: #0056b3;
}

/* Table styles */
.admin-table {
    width: 100%;
    border-collapse: collapse;
}

.admin-table thead th {
    font-size: 24px;
}

.admin-table tbody tr td {
    font-size: 24px;
}


.admin-table th, .admin-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .admin-container {
        padding: 10px;
    }

    .input-group {
        flex-direction: column;
    }

    .input-group input {
        width: 100%;
    }
}

/* Add this CSS to your stylesheet or in the same file where you import your styles */
.admin-button {
    background-color: #3498db;
    color: #ffffff;
    border: none;
    height: 40px;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
font-size: 18px;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */

    &:disabled {
        background-color: #bdc3c7;
        cursor: not-allowed;
    }
}

.admin-button_log {
    background-color: #3498db;
    color: #ffffff;
    border: none;
    height: 60px;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 18px;
}

.top_Section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sign_out_button {
    background-color: #1a1a1a;
    color: #ffffff;
    border: none;
    height: 40px;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}
.input-group input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;

    &:disabled {
        background-color: #f2f2f2;
        cursor: not-allowed;
    }
}
