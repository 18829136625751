
.RulesAndFees{
    margin-top: 20px;
    display: flex;
    width: 300px;
    flex-direction: row;
    justify-content: stretch;

}

#RulesBlockID{
    visibility: visible;
    display: block;
    width: 300px;
    background-color: linen;
    border-bottom-right-radius: 25px;
    padding: 10px;
}


#RulesAndFeesbtn{
    padding-left: 45px;
    width: 300px;
    text-align: center;
    border: solid 0px;
    height: 50px;
    font-family: codenext;
    font-size: 18px;
    background-color: linen;
    border-top-left-radius: 25px;

}

#RulesAndFeesbtn2{
    width: 50px;
    height: 50px;
    border: solid 0px;
    background-image: url("../files/icons8-chevron_down_filled.png");
    background-color: linen;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
}
.fee{
    color: red;
    margin-top: 5px;
    margin-bottom: 20px;
}

