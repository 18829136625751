
.page {
    background: #2b2d30;

}

.Login {
    display: flex;
    height: 900px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    align-content: center;

}
.logo {

    background: url(../files/logo.png);
    margin-top: 50px;
    width: 70vh;
    height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.userInputsblock {
    padding: 10px;
}

.checkboxblock{
    padding: 10px 10px;

}

.checkboxtext {
    font-family: Arial;
}

.userinput {

    width: 300px;
    height: 40px;
    text-overline-color: black;
    background-color: white;
    border-radius: 5px;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
}

@font-face {
    font-family:"codenext";
    src: url("../files/codenext.otf") format("truetype");
}

.msg {
    display: block;
    color: black;
    height: 50px;

}

.loginBtn {
    width: 100px;
    height: 35px;
    margin-top: 10px;
    border-radius: 5px;
    border-color: white;
    background-color: white;
    font-family: "codenext";

}
