/* KofegorPage.css */

/* Styling for the main container */
.MainDiv {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #2b2d30;
}


.page {
    background: #2b2d30;

}


/* Styling for the competition title */


/* Styling for the competition cards */
.competition-card {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 0 auto; /* Center horizontally */
    opacity: 1;
    transition: transform 2s ease-in-out;
    position: absolute;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    width: 90%;
}

.tableTitle {
    font-size: 48px;
    font-family: "Avenir Next", sans-serif;
    margin-bottom: 20px;
    margin-top: 50px;

}

#goalTitle {
    font-size: 50px;
    font-family: codenext, serif;
    color: #000033;

}
#prizeTitle {
    font-size: 50px;
    font-family: codenext, Serif;
    color: #dab520;

}
/* Styling for the tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border-collapse: collapse;
}



table th, table td {
    border: 1px solid #000; /* Set the border thickness (2px in this example) */
    padding: 8px;
    text-align: center;
    font-size: 40px;
}


/* Highlighting the highest average employee */
#tbody1 tr:first-child td {
    font-weight: bold;
    font-family: codenext;
    font-size: 44px;
    background-color: #15b400; /* Change the background color as needed */
}

thead {
    background-color: black;
    color: white; /* Optionally, set text color to white for better visibility */
}


/* Add additional CSS styles and animations as needed */
/* Animation for card swapping */
@keyframes slideRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(-60%);
        opacity: 1;
    }
}

/* Animation for card leaving */
@keyframes slideLeft {
    0% {
        transform: translateX(-50%);
        opacity: 1;
    }
    100% {
        transform: translateX(-150%);
        opacity: 0;
    }
}

/* Apply the animation to competition cards */
.competition-card-enter {
    animation: slideRight 3s forwards;
}

/* Apply the exit animation when leaving a competition card */
.competition-card-exit {
    animation: slideLeft 3s forwards;
    animation-fill-mode: forwards; /* Make sure the final keyframe persists */
}

/* Keep the card container fixed */
.competition-card-container {
    position: relative;
    width: 100%;
    height: 100%;
}

body {
    background-color: #fff; /* White background color */
}
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap');


#competition_main_title {
    font-size: 60px;
    font-family: 'Merienda', sans-serif;
    margin: 1rem auto;
    color: transparent;
    background: -webkit-linear-gradient(left, #dab520, #ffffff, #dab520);
    background: linear-gradient(to right, #dab520, #f1f1f1, #dab520);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: flick 5s infinite;
}
@keyframes flick {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}





